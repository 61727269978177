import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import React from 'react'
import Button from '../common/Button'

const Resource = ({ resource, hideButton = false }) => {
  return (
    <div className="flex flex-col max-w-xl w-full px-6 mx-auto">
      <GatsbyImage
        alt={resource.title}
        image={resource?.image?.gatsbyImage}
        objectFit="contain"
        className="m-2 h-64 w-full max-w-sm mx-auto"
      />
      <h4 className="font-bold text-2xl">{resource.title}</h4>
      <div className="flex flex-col items-start align mt-4 gap-4 text-gray-400">
        <span className="mt-3 text-base text-gray-500 line-clamp-4">
          {resource.description?.raw && renderRichText(resource.description)}
        </span>
        {!hideButton && (
          <Button
            href={`/request-download/${resource.slug}`}
            label="Free download"
          />
        )}
      </div>
    </div>
  )
}

export default Resource
