import { graphql } from 'gatsby'
import get from 'lodash/get'
import React from 'react'
import GeneralCTA from '../components/cta/GeneralCTA'
import Layout from '../components/layout'
import Form from '../components/resources/Form'
import Resource from '../components/resources/Resource'

class RequestDownloadTemplate extends React.Component {
  render() {
    const resource = get(this.props, 'data.contentfulDownloads')
    // const plainTextDescription = documentToPlainTextString(
    //   JSON.parse(resource.description.raw)
    // )

    console.log(resource)
    return (
      <Layout location={this.props.location}>
        {/* <Seo title={resource.title} description={resource.title} /> */}
        <div className="flex flex-col lg:flex-row py-28 max-w-7xl mx-auto gap-8">
          <Form resource={resource} />
          <Resource resource={resource} hideButton />
        </div>
        <GeneralCTA />
      </Layout>
    )
  }
}

export default RequestDownloadTemplate

export const pageQuery = graphql`
  query DownloadBySlug($slug: String!) {
    contentfulDownloads(slug: { eq: $slug }) {
      title
      fileRef
      slug
      description {
        raw
      }
      order
      image {
        gatsbyImage(layout: FULL_WIDTH, height: 800)
      }
    }
  }
`
