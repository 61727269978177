import React from 'react'
import Button from '../common/Button'

export default function CallToAction() {
  return (
    <div className="bg-ufblack">
      <div className="mx-auto max-w-7xl px-6 py-24 lg:flex lg:items-center lg:justify-between lg:px-8">
        <div className="flex flex-col space-y-4">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
            Want to find out more? Let’s talk.
          </h2>
          <p className="mt-4 max-w-3xl text-lg text-white">
            We help local authorities rollout EV charging infrastructure for a
            cleaner, greener future.
          </p>
        </div>
        <div className="mt-10 flex flex-col sm:flex-row items-start sm:items-center gap-6 lg:mt-0 lg:flex-shrink-0">
          <Button label="Contact us" href="/contact" />
          <Button label="Download Brochure" href="/downloads" />
        </div>
      </div>
    </div>
  )
}
